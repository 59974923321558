export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50')
];

export const server_loads = [0,8];

export const dictionary = {
		"/(app)": [10,[2]],
		"/(app)/actions": [11,[2]],
		"/archive/kanban/[team]": [~46],
		"/archive/[journey]": [~45,[9]],
		"/auth/login": [47],
		"/auth/pwd_reset_error": [48],
		"/auth/pwd_reset_req_ack": [49],
		"/auth/signup": [50],
		"/(app)/card/[id]": [~12,[2]],
		"/(app)/data": [13,[2]],
		"/(app)/data/budget": [~14,[2]],
		"/(app)/data/journey": [~15,[2]],
		"/(app)/data/lifecycle": [~16,[2]],
		"/(app)/data/measure": [~17,[2]],
		"/(app)/data/multiple": [~18,[2]],
		"/(app)/data/system": [~19,[2]],
		"/(app)/data/team": [~20,[2]],
		"/(app)/data/unit": [~21,[2]],
		"/(app)/goalflow": [~22,[2]],
		"/(app)/kanban": [23,[3]],
		"/(app)/kanban/[team]": [~24,[3]],
		"/(app)/on1_strategy": [25,[4]],
		"/(app)/on1_strategy/[id]": [~26,[4]],
		"/(app)/on2_gems": [27,[5]],
		"/(app)/on2_gems/[id]": [~28,[5]],
		"/(app)/on3_provision": [29,[6]],
		"/(app)/on3_provision/[id]": [~30,[6]],
		"/(app)/on_network": [31,[7]],
		"/(app)/on_network/[id]": [~32,[7]],
		"/(app)/plan/thefuture": [~33,[2]],
		"/(app)/plan/thepresent": [~34,[2]],
		"/(app)/study": [~35,[2,8]],
		"/(app)/study/stages": [36,[2,8]],
		"/(app)/study/timeboxes": [~37,[2,8]],
		"/(app)/study/timeboxes/[sprintNo]": [~38,[2,8]],
		"/(app)/user/pwd/change": [39,[2]],
		"/(app)/user/pwd/set": [40,[2]],
		"/(app)/user/welcome": [41,[2]],
		"/(app)/wsj": [~42,[2]],
		"/(app)/wsj/[team]": [~43,[2]],
		"/(app)/wsj/[team]/[stage]": [~44,[2]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';